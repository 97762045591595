.field-error {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.input-error input {
    border: 1px solid theme("colors.vividRed") !important;
}

.input-error textarea {
    border: 1px solid theme("colors.vividRed") !important;
}

.field-error img {
    margin-right: 6px;
}
