.container {
  display: grid;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
  grid-template-areas:
    "meet"
    "cale"
    "noti"
    "cont"
    "sess";
}

.container:last-child {
  margin-bottom: 40px;
}

@media (min-width: 768px) { 
  .container {
    grid-template-areas:
      "meet meet"
      "noti cont";
  }
}

@media (min-width: 1024px) { 
  .container {
    grid-template-areas:
      "meet meet cale"
      "meet meet sess"
      "noti cont sess";
  }
}

.scrollBar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  scrollbar-color: transparent transparent;
}

.scrollBar::-webkit-scrollbar {
  display: none; 
}