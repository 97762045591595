.select__control {
    height: 56px !important;
    border: 1px solid #d1d9e0 !important;
    border-radius: 10px !important;
}

.credentials .select__menu {
    max-height: 200px !important;
    overflow: scroll !important;
}

.credentials .select__option:last-child {
    padding-bottom: 110px;
}