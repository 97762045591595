.react-datepicker-wrapper {
    height: 56px !important;
    border: 1px solid #d1d9e0 !important;
    border-radius: 10px !important;
}

.react-datepicker__input-container {
    width: 100%;
    height: 100%;
    border-radius: 10px !important;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 100%;
    border-radius: 10px !important;
    text-indent: 30px !important;
    color: #7D878F !important;
    font-size: 16px !important;
}

.react-datepicker__input-container input:hover{
    outline: none;
    border: 1px solid theme('colors.grayFlash');
    background-color: theme('colors.grayFlash');
    color: theme('colors.grayCharcoal');
}

.react-datepicker__input-container input:focus{
    outline: none;
    border: 1px solid theme('colors.royalBlue');
    background-color: theme('colors.white');
    color: theme('colors.black');
}

.react-datepicker {
    border: none !important;
    border-radius: 16px !important;
    box-shadow: 0px 16px 80px -8px rgba(0, 0, 0, 0.08) !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background-color: #3395FF !important;
    color: white !important;
    padding: 20px 0 !important;
}

.react-datepicker__header--time {
    height: 88px !important;
}

.react-datepicker-time__header {
    color: white !important;
}

.react-datepicker__time-container {
    border-left: 2px solid #E8EFF5;
}

.react-datepicker__current-month, .react-datepicker__day-name {
    color: white !important;
    font-weight: bold;
}

.react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    margin-top: 20px;
}

.react-datepicker__day--today {
    background-color: #3A434A !important;
    border-radius: 50%;
    color: white !important;
    font-weight: bold;
}

.react-datepicker__day--selected {
    background-color: #3395FF  !important;
    border-radius: 50%;
    color: white !important;
    font-weight: bold;
}

.react-datepicker__time-list-item--selected {
    background-color: #3395FF !important;
}