.session-controls-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.zoee-session-detail-gradient {
    background: linear-gradient(90.69deg, #4481eb -4.41%, #04befe 102.43%);
}

.google-session-detail-gradient {
    background: linear-gradient(90.69deg, #00cdac -4.41%, #8ddad5 102.43%);
}

.office365-session-detail-gradient {
    background: linear-gradient(90.69deg, #ff9a9e -4.41%, #fad0c4 102.43%);
}

.card-blur-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.64;
}

.growth-subscription-gradient {
    background: linear-gradient(140.49deg, #3886f7 2.39%, #892ff4 124.46%);
}

.teams-subscription-gradient {
    background: linear-gradient(135.15deg, #373737 -21.14%, #141414 84.79%);
}

.add-stripe-connect-gradient {
    background: linear-gradient(56.55deg, #892ff4 13.59%, #3395ff 100.7%);
}

.non-active-profile-session-detail-gradient {
    background: linear-gradient(90.69deg, #000000 -4.41%, #484848 102.43%);
}

.non-zoee-managed-session-detail-gradient {
    background: linear-gradient(90.69deg, #a0a0a0 -4.41%, #cacaca 102.43%);
}

.gray-background-gradient {
    background: linear-gradient(180deg, #F2F5F7 0%, rgba(242, 245, 247, 0) 100%);
}

.blog-gray-background-gradient {
    background: linear-gradient(358.57deg, #F2F5F7 1.34%, rgba(242, 245, 247, 0) 98.93%);
}

.subscription-cta-gradient {
    background: linear-gradient(90deg, #F5E04A 0%, #E8C7C7 100%);
}

.subscription-cta-gradient-border {
    position: relative;
    border-radius: 32px;
    background: linear-gradient(90deg, #F5E04A 0%, #E8C7C7 100%);
    padding: 4px; 
}
  
.subscription-cta-gradient-border .inner-content {
    background: white;
    border-radius: 28px; 
    padding: 24px 32px; 
    width: 100%;
    height: 100%;
}
  