/* adjust chat css */
.str-chat {
    max-height: 90vh;
}

/* channel list */
.str-chat-channel {
    height: 88%;
    width: 100%;
    border: none;
    outline: none;
}

.str-chat.messaging {
    background-color: transparent;
}

.str-chat-channel-list {
    width: 100%;
    max-height: 76vh;
}

.str-chat-channel-list.messaging {
    position: static !important;
    background-color: transparent;
    box-shadow: none !important;
}

.str-chat__channel-list-messenger {
    background-color: #fcfcfc;
    overflow-y: auto;
    max-height: 76vh;
}

.str-chat__channel-list-messenger__main p {
    font-family: "BeausiteClassic";
}

@media (min-width: 768px) {
    .str-chat__channel-list-messenger__main {
        height: 66vh;
    }
}

/* chat window images */

.str-chat__avatar-image, .str-chat__avatar-fallback {
    object-fit: fill !important;
    background-color: theme('colors.blurple');
    font-weight: bold;
}

/* channel search input */
.str-chat__channel-search {
    background-color: transparent;
    position: relative;
    padding-top: 0;
}

.str-chat__channel-search-input {
    margin: auto;
    width: 335px;
    height: 48px !important;
    border: 1px solid #D1D9E0 !important;
    border-radius: 100px !important;
    text-indent: 48px;
    color: theme('colors.graySlate') !important;
    padding: 0 !important;
}

.str-chat__channel-search-input:hover {
    border: 1px solid theme('colors.grayFlash');
    background-color: theme('colors.grayFlash');
    color: theme('colors.grayCharcoal');
}

.str-chat__channel-search-input:focus{
    outline: none;
    border: 1px solid theme('colors.royalBlue') !important;
    background-color: theme('colors.white');
    color: theme('colors.black');
    box-shadow: none !important;
}

.str-chat__channel-search-container {
    margin: auto;
    left: 50%;
    top: 80px !important;
    transform: translateX(-50%);
    border-radius: 16px;
    max-height: 500px;
    width: 90%;
    overflow-y: scroll;
    background-color: #fff !important;
    box-shadow: 0px 16px 80px -8px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.str-chat__modal .str-chat__modal__inner {
    width: 95% !important;
}

/*  edit message modal */
.str-chat__modal .str-chat__edit-message-form textarea {
    height: 200px !important; 
    font-size: 1.5rem; 
    resize: vertical !important; 
    overflow-y: auto; 
  }
  
  .str-chat__modal .str-chat__edit-message-form {
    width: 100% !important;
  }

  .str-chat__modal .str-chat__edit-message-form button:last-child {
    margin-left: 10px;
  }
  
  .str-chat__edit-message-form-options {
    gap: 10px !important;
  }
  

/* chat input adjustments */
.str-chat__input-flat {
    border-radius: 0 0 16px 16px;
}

.str-chat__textarea>textarea {
    border: 1px solid theme('colors.grayCloud') !important;
}

.str-chat__textarea>textarea:focus {
    border: 1px solid theme('colors.royalBlue') !important;
    outline: none;
    box-shadow: none !important;
}

/* channel list loading indicator */
.str-chat__loading-channels {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    border-radius: 16px;
    height: 100%;
}

/* chat bubbles */
.str-chat__li {
    font-family: 'BeausiteClassic' !important;
}

.str-chat__message-text-inner {
    background-color: theme('colors.grayFlash');
    font-size: 14px !important;
    padding: 16px;
}

.str-chat__message-text-inner p {
    font-family: 'BeausiteClassic' !important;
}

.str-chat__message--me .str-chat__message-text-inner {
    background-color: theme('colors.blurple') !important;
    color: theme('colors.white') !important;
}

.str-chat__li .quoted-message.mine .quoted-message-inner {
    color: theme('colors.blurple');
}

/* fix emoji/reaction spacing issues */
.str-chat__message-simple-text-inner.str-chat__message-simple-text-inner--is-emoji p {
    line-height: normal;
}

.str-chat__reaction-list li {
    padding-top: 4px;
}

/* media queries */

@media (max-width: 768px) {
    .str-chat {
        max-height: 100vh;
    }

    .str-chat-channel {
        height: calc(100vh - 90px) !important;
    }

    .beginning-conversation {
        margin-bottom: 1rem;
    }
}

.str-chat-channel.messaging .str-chat__main-panel {
    height: 85%;
}

@media screen and (min-width: 768px) {
    .str-chat-channel.messaging .str-chat__main-panel {
        padding-bottom: 0 !important;
        height: 100%;
    }
    
}