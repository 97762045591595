.container {
    display: grid;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
    grid-template-areas:
      "meet"
      "cale"
      "noti"
      "cont"
      "sess";
  }
  
  .container:last-child {
    margin-bottom: 40px;
  }
  
  @media screen(md) {
    .container {
      grid-template-areas:
        "meet meet"
        "noti cont";
    }
  }
  
  @media screen(lg) {
    .container {
      grid-template-areas:
        "meet meet cale"
        "meet meet sess"
        "noti cont sess";
    }
  }


  .scrollBar {
    msOverflowStyle: "none";
    scrollbarWidth: none;
    scrollbar-color: transparent transparent; /* For Firefox */
  }
  
  .scrollBar::-webkit-scrollbar {
    display: none;
  }
  