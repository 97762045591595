.inverted-text-stroke {
    position: absolute;
    -webkit-text-stroke: 4px black;
    letter-spacing: 4px;
    font-size: 78px;
}

.inverted-text-no-stroke {
    color: white;
    position: absolute;
    letter-spacing: 4px;
    font-size: 78px;
}

.inverted-text-stroke-medium {
    position: absolute;
    -webkit-text-stroke: 4px black;
    letter-spacing: 4px;
    font-size: 56px;
}

.inverted-text-no-stroke-medium {
    color: white;
    position: absolute;
    letter-spacing: 4px;
    font-size: 56px;
}

.inverted-text-stroke-small {
    position: absolute;
    -webkit-text-stroke: 4px black;
    letter-spacing: 4px;
    font-size: 50px;
}

.inverted-text-no-stroke-small {
    color: white;
    position: absolute;
    letter-spacing: 4px;
    font-size: 50px;
}

.landing-inverted-text-stroke {
    position: absolute;
    -webkit-text-stroke: 4px black;
    letter-spacing: 4px;
    font-size: 63px;
}

.landing-inverted-text-no-stroke {
    color: #E8EFF5;
    position: absolute;
    letter-spacing: 4px;
    font-size: 63px;
}

.landing-inverted-text-stroke-med-lg {
    position: absolute;
    -webkit-text-stroke: 3px black;
    letter-spacing: 3px;
    font-size: 42px;
}

.landing-inverted-text-no-stroke-med-lg {
    color: #E8EFF5;
    position: absolute;
    letter-spacing: 3px;
    font-size: 42px;
}

.landing-inverted-text-stroke-medium {
    position: absolute;
    -webkit-text-stroke: 4px black;
    letter-spacing: 4px;
    font-size: 56px;
}

.landing-inverted-text-no-stroke-medium {
    color: #E8EFF5;
    position: absolute;
    letter-spacing: 4px;
    font-size: 56px;
}

.landing-inverted-text-stroke-small {
    position: absolute;
    -webkit-text-stroke: 3px black;
    letter-spacing: 3px;
    font-size: 46px;
}

.landing-inverted-text-no-stroke-small {
    color: #E8EFF5;
    position: absolute;
    letter-spacing: 3px;
    font-size: 46px;
}

.landing-inverted-text-stroke-xs {
    position: absolute;
    -webkit-text-stroke: 2px black;
    letter-spacing: 3px;
    font-size: 31px;
}

.landing-inverted-text-no-stroke-xs {
    color: #E8EFF5;
    position: absolute;
    letter-spacing: 3px;
    font-size: 31px;
}