.scroll-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
  
    /* Hide scrollbar in Firefox */
    scrollbar-width: none;
  
    /* Hide scrollbar in Webkit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      display: none;
    }
  }