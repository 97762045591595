.container {
  display: grid;
  justify-content: center;
  gap: 16px;
  grid-template-areas:
    "vide"
    "test"
    "serv"
    "blog"
    "soci"
    "newsletter";
}

.container:last-child {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .container {
    grid-template-areas:
      "serv serv"
      "blog vide"
      "blog vide"
      "test vide"
      "test soci"
      "newsletter newsletter";
  }
}

@media screen and (min-width: 1024px) {
  .container {
    grid-template-areas:
      "serv serv vide"
      "blog test vide"
      "blog test vide"
      "blog test soci"
      "newsletter newsletter newsletter";
  }
}

@media screen and (min-width: 1280px) {
  .container {
    grid-template-areas:
      "cons serv serv vide"
      "cons serv serv vide"
      "cons serv serv vide"
      "cons blog test vide"
      "cons blog test soci"
      "newsletter newsletter newsletter newsletter";
  }
}
