.check-box {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    border: 0.5px solid #E3E6EA;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.check-box img {
    display: none;
}

.check-box-checked {
    border: 0.5px solid theme('colors.black');
}

.check-box-checked img {
    display: block;
}