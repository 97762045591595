.btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 48px;
    background-color: theme('colors.mainBlack');
    color: #fff !important;
    cursor: pointer;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
}

.btn-primary-disabled {
    cursor: default !important;
}

.btn-primary-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 48px;
    background-color: theme('colors.mainBlack');
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
}

.btn-outline-primary {
    background-color: transparent !important;
    color: theme('colors.black') !important;
    border: 1px solid theme('colors.black') !important;
}

.btn-secondary-nav {
    background-color: theme('colors.grayMist') !important;
    color: theme('colors.black') !important;
}

.btn-secondary {
    background-color: theme('colors.lightGray') !important;
    color: theme('colors.black') !important;
    font-weight: bold;
    border-radius: 10px;
}

.btn-blue {
    background-color: theme('colors.primaryBlue') !important;
    color: theme('colors.white') !important;
    border: 1px solid theme('colors.primaryBlue') !important;
}

.btn-white {
    background-color: theme('colors.white') !important;
    color: theme('colors.primaryBlue') !important;
    border: 1px solid theme('colors.white') !important;
}

.btn-primary:disabled {
    border: 1px solid theme('colors.grayCloud');
    color: theme('colors.gray') !important;
    background-color: theme('colors.white');
}

.btn-primary:disabled:hover {
    border: 1px solid theme('colors.grayCloud');
    color: theme('colors.gray') !important;
    background-color: theme('colors.white');
}

.btn-primary:hover{
    background-color: theme('colors.black');
}

.btn-blue:disabled {
    background-color: theme('colors.paleCyanBlue') !important;
    color: theme('colors.white') !important;
    border: 1px solid theme('colors.paleCyanBlue') !important;
    opacity: 50%;
}

.btn-blue:disabled:hover {
    color: theme('colors.white') !important;
}