@tailwind components;

@layer components {
    .box-shadow-1 {
        box-shadow: 0px 15px 48px rgba(17, 17, 17, 0.06);
    }
    
    .box-shadow-2 {
        box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.08);
    }
    
    .bottom-nav-shadow {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.02);
    }
    
    .settings-card-shadow {
        box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.06);
    }
    
    .card-hover-shadow {
        box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.16);
    }
    
    .main-shadow {
        box-shadow: 0px 16px 80px -8px rgba(0, 0, 0, 0.08);
    }
    
    
    .phone-confirm-popup-shadow {
        box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08);
    }
    
    .promotion-shadow {
        box-shadow: 0px 16px 80px -8px rgba(0, 0, 0, 0.08);
    }

    .nav-shadow {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02);
    }

    .create-service-shadow {
        box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.01);
    }

    .attendee-toggle-shadow {
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
    }

    .card-icon-shadow {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    }

    .modal-shadow-lg {
        box-shadow: 0px 64px 150px rgba(0, 0, 0, 0.24);
    }

    .subscription-plan-tag-shadow {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    }

    .pricing-toggle-square-box-shadow {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    }

    .top-shadow {
        box-shadow: 0px -4px 31px rgba(0, 0, 0, 0.16);
    }

    .feature-shadow {
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    }
}
