@tailwind components;

@layer components {
    .zoee-backdrop-filter {
        background: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(2px);
    }
    .dark-gray-blurred {
        background: rgba(0, 0, 0, 0.64);
        backdrop-filter: blur(12px);
    }
    .white-blur {
        background: #FFFFFF;
        filter: blur(32px);
    }
    .profile-video-filter {
        background: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(2px);
    }
}