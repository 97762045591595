/* calendar sync */

#cronofy-calendar-sync {
    width: 100%;
}

.CalendarSync__status-label {
    margin-bottom: 4px;
}

.CalendarSync {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.CalendarSync p {
    margin-bottom: 20px;
    color: theme('colors.graySlate');
    text-align: center;
    padding: 0;
    font-size: 14px;
}

.CalendarSync__title {
    display: none;
}

.CalendarSync__status--active {
    border-bottom: none !important;
    margin: none !important;
}

.CalendarSync__provider-auth {
    width: 100% !important;
    border-radius: 10px !important;
    padding: 10px !important;
    border: 1px solid theme('colors.grayCloud') !important;
}

@media screen and (min-width: 640px) {
    .CalendarSync__providers {
        justify-content: space-between !important;
    }
    .CalendarSync__provider-auth {
        width: 48% !important;
        border-radius: 10px !important;
        padding: 10px !important;
        border: 1px solid theme('colors.grayCloud') !important;
    }
    .CalendarSync p {
        width: 50%;
    }
    .CalendarSync__status--active {
        border-bottom: none !important;
        margin: none !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
}