body {
	font-family: BeausiteClassic, system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100vh;
    min-height: 100%;
}
h1 {
    @apply font-medium text-[48px] -tracking-[0.04em] text-[#231F20];
}
button {
    @apply border-0;
    @apply outline-none;
    @apply bg-none;
}
a {
    text-decoration: none;
}
