/* generated by https://loading.io/ */

.loaderWrapper {
  --color-one: #bacdff;
  --color-two: #7291ff;
  --color-three: #4750f5;
  --color-four: #171d8b;
}

@keyframes loading-indicator {
  0% {
    transform: translateX(6px) scale(0);
  }
  25% {
    transform: translateX(6px) scale(0);
  }
  50% {
    transform: translateX(6px) scale(1);
  }
  75% {
    transform: translateX(40px) scale(1);
  }
  100% {
    transform: translateX(74px) scale(1);
  }
}

@keyframes loading-indicator-r {
  0% {
    transform: translate(74px, 0) scale(1);
  }
  100% {
    transform: translate(74px, 0) scale(0);
  }
}

@keyframes loading-indicator-c {
  0% {
    background: var(--color-one);
  }
  25% {
    background: var(--color-two);
  }
  50% {
    background: var(--color-three);
  }
  75% {
    background: var(--color-four);
  }
  100% {
    background: var(--color-one);
  }
}

.loadingIndicator div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateX(40px) scale(1);
  background: var(--color-one);
  animation: loading-indicator 2.4390243902439024s infinite
    cubic-bezier(0, 0.5, 0.5, 1);
}

.loadingIndicator div:nth-child(1) {
  background: var(--color-four);
  transform: translateX(74px) scale(1);
  animation: loading-indicator-r 0.6097560975609756s infinite
      cubic-bezier(0, 0.5, 0.5, 1),
    loading-indicator-c 2.4390243902439024s infinite step-start;
}

.loadingIndicator div:nth-child(2) {
  animation-delay: -0.6097560975609756s;
  background: var(--color-one);
}

.loadingIndicator div:nth-child(3) {
  animation-delay: -1.2195121951219512s;
  background: var(--color-four);
}

.loadingIndicator div:nth-child(4) {
  animation-delay: -1.8292682926829267s;
  background: var(--color-three);
}

.loadingIndicator div:nth-child(5) {
  animation-delay: -2.4390243902439024s;
  background: var(--color-two);
}

.loaderWrapper {
  width: 58px;
  height: 12px;
  display: inline-block;
  overflow: hidden;
}

.loadingIndicator {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.58);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
