.default-input {
    width: 360px;
    height: 56px;
    position: relative;
}

.default-input .overlay {
    color: theme('colors.graySlate') !important;
    position: absolute;
    top: 8px;
    left: 20px;
    display: none;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: -0.03em;
}

.default-input input, textarea {
    width: 100%;
    height: 100%;
    text-indent: 20px;
    font-weight: 400;
    font-size: 16px;
    color: theme('colors.graySlate');
    border: 1px solid theme('colors.grayCloud');
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: -0.03em;
}

.default-input input:hover, textarea:hover {
    outline: none;
    border: 1px solid theme('colors.grayFlash');
    background-color: theme('colors.grayFlash');
    color: theme('colors.grayCharcoal');
}

.default-input input:focus, textarea:focus{
    outline: none;
    border: 1px solid theme('colors.royalBlue');
    background-color: theme('colors.white');
    padding-top: 16px;
    color: theme('colors.black');
}

.default-input input:focus + .overlay, textarea:focus + .overlay {
    display: block;
    color: theme('colors.graySlate');
  }

.default-input input[type='password']:not(:placeholder-shown){
    letter-spacing: 8px;
    color: theme('colors.mainBlack');
}

.default-input input[type="text"]:disabled{
    background-color: transparent;
    border: 1px solid theme('colors.grayCloud') !important;
    color: theme('colors.gray');
}

.default-input input[type="password"]:disabled{
    background-color: transparent;
    border: 1px solid theme('colors.grayCloud') !important;
    color: theme('colors.gray');
}

.default-input input[type="tel"]:disabled{
    background-color: transparent;
    border: 1px solid theme('colors.grayCloud') !important;
    color: theme('colors.gray');
    cursor: default;
}

.default-input .show-hide-button {
    position: absolute;
    right: 16px;
    top:  50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 10px;
    color: theme('colors.mainBlack');
    line-height: 16px;
    cursor: pointer;
}

.show-hide-button {
    position: absolute;
    right: 16px;
    top:  50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 10px;
    color: theme('colors.mainBlack');
    line-height: 16px;
    cursor: pointer;
}

.search-input {
    position: relative;
    width: 335px;
    height: 48px;
    @apply rounded-md;
}

.search-input input {
    width: 100%;
    height: 100%;
    border: 1px solid #D1D9E0;
    @apply rounded-md;
    text-indent: 48px;
    color: theme('colors.graySlate');
}

.search-input input:hover {
    border: 1px solid theme('colors.grayFlash');
    background-color: theme('colors.grayFlash');
    color: theme('colors.grayCharcoal');
}

.search-input input:focus{
    outline: none;
    border: 1px solid theme('colors.royalBlue');
    background-color: theme('colors.white');
    color: theme('colors.black');
}

.search-input img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}

.mobile-browser-disabled-style-override {
    -webkit-text-fill-color: theme('colors.gray'); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    opacity: 1;
    color: theme('colors.gray'); /* Override IE font color change */
}

@media screen and (max-width: 400px) {
    .default-input {
        width: 335px;
    }
    .default-input input[type="text"]{
        border-color: theme('colors.graySlate');
        color: theme('colors.grayCharcoal');
    }
    .default-input input[type="password"]{
        border-color: theme('colors.graySlate');
        color: theme('colors.grayCharcoal');
    }
    .default-input input[type="text"]:disabled{
        border-color: theme('colors.graySlate');
        color: theme('colors.grayCharcoal');
    }
    .default-input input[type="password"]:disabled{
        border-color: theme('colors.graySlate');
        color: theme('colors.grayCharcoal');
    }
}